<template>
    <div class="d-flex justify-content-start">
        <label class="me-2" style="margin-top:6px;">{{ $t('Repeat every') }}</label>
        <div >
            <OSelect v-model="number" class="form-control form-control-sm me-2" @selected="tryOptionUpdate" style="width:60px;">
                <template v-for="number in numbersArr">
                    <option :value="number">{{ number }}</option>
                </template>
            </OSelect>
        </div>
    
        <div>
            <OSelect v-model="option" class="form-control form-control-sm " @selected="tryDaysUpdate" style="width:100px;">
                <option v-if="number == 1" value="day">{{ $t("day") }}</option>
                <option v-else value="days">{{ $t("days") }}</option>
                <option v-if="number == 1" value="week">{{ $t("week") }}</option>
                <option v-else value="weeks">{{ $t("weeks") }}</option>
                <option v-if="number == 1" value="month">{{ $t("month") }}</option>
                <option v-else value="months">{{ $t("months") }}</option>
                <option value="year">{{ $t("year") }}</option>
            </OSelect>
        </div>
            
        
    </div>
    <div v-if="renderDaySelector" class="d-flex justify-content-start w-100 pe-4 pt-3">
        <template v-for="(day, i) in days">
            <button class="btn hover-class me-2" :class="[isDaySelected(day)]" style="min-width: 40px;"
                @click="handleSelect(day)" :title="day">
                {{ daysTranslated[i].substring(0,2) }}
            </button>
        </template>
    </div>
    <div v-if="renderRadioSelector" class="mt-2">
        <template v-for="(occurrence, index) in occurrenceArr" :key="occurrence">
            <div class="form-check" >
                <input v-model="selectedValueMonthIndex" :value="index" class="form-check-input" type="radio" name="day"
                    :id="`radio_${index}`">
                <label class="form-check-label" :for="`radio_${index}`">
                    {{ occurrenceArrTranslated[index].charAt(0).toUpperCase() + occurrenceArrTranslated[index].slice(1) }}
                </label>
            </div>
        </template>
    </div>
    <div class="pt-2">
        {{ convertFormulaToExplanation(formula, startDate, null, null) }}
    </div>
</template>

<script setup lang="ts">
import { OSelect } from 'o365-ui-components';
import { ref, onMounted, computed, watch, defineEmits, defineExpose } from 'vue';
import { getSelectedOptions, convertFormulaToExplanation } from 'o365.libraries.FrequencyEditorLibrary.helpers.ts'
import { $t } from 'o365-utils';
const props = defineProps(['startDate', 'endDate', 'formula']);

watch(() => props.startDate, () => {
    if (option.value === 'month' || option.value === 'months' || option.value === 'year') {
        if (selectedValueMonthIndex.value == 2 && occurrenceArr.value.length < 3) {
            selectedValueMonthIndex.value = 1;
        }

        if (selectedDay.value.length) {
            selectedDay.value = [];
        }

        if (props.startDate) {
            handleSelect(getDayName(props.startDate.getDay()));
        }
    }

    if (["week", "weeks"].includes(option.value)) {
        if (props.startDate) {
            selectedDay.value.length ? emit('update:frequency', formula.value) : handleSelect(getDayName(props.startDate.getDay()));
        } else if (selectedDay.value.length) {
            selectedDay.value = [];
        }
    }
});

watch(() => props.formula, () => {
    if (["week", "weeks"].includes(option.value) && props.formula === null) {
        selectedDay.value = [];
    }
});

const emit = defineEmits(['update:startTime', 'update:frequency']);
const numberDefault = 1;
const numbersArr = [...Array(99).keys()].map(x => x + 1);
const number = ref(numberDefault);
const optionDefault = 'week';
const option = ref(optionDefault);

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const daysTranslated = [$t('Monday'), $t('Tuesday'), $t('Wednesday'), $t('Thursday'), $t('Friday'), $t('Saturday'), $t('Sunday')];
const monthsTranslated = [$t('January'), $t('February'), $t('March'), $t('April'), $t('May'), $t('June'), $t('July'), $t('August'), $t('September'), $t('October'), $t('November'), $t('December')];
const occurrenceArrTranslated = ref<string[]>([]);
const selectedDay = ref<string[]>([]);
const selectedDayTranslated = computed(() => {
    if (!selectedDay.value.length) {
        return '';
    }

    const dayIndex = days.indexOf(selectedDay.value[0]);
    return daysTranslated[dayIndex];
});

const renderDaySelector = ref(true);
const renderRadioSelector = ref(false);

const occurrenceArr = computed(() => {
    if (!props.startDate) {
        occurrenceArrTranslated.value = [];
        return [];
    }
    
    const vOccurrenceArr: string[] = [];
    occurrenceArrTranslated.value = [];
    const dayOfWeek = props.startDate.getDay();
    const dayOfMonth = props.startDate.getDate();

    const firstOfMonthDayOfWeek = new Date(props.startDate.getFullYear(), props.startDate.getMonth(), 1).getDay();

    let offset = (dayOfWeek + 7 - firstOfMonthDayOfWeek) % 7;
    if (offset === 0) offset = 7;

    const occurrence = Math.floor((dayOfMonth - 1) / 7) + 1 - Math.floor((offset - 1) / 7);

    if (option.value === 'month' || option.value === 'months') {
        occurrenceArrTranslated.value.push($t('on day') + ` ${props.startDate.getDate()}` )
        vOccurrenceArr.push(`on day ${props.startDate.getDate()}`);
        if (occurrence == 4) {
            occurrenceArrTranslated.value.push($t('on the') + ` ${getNumberTranslated(occurrence)} ${selectedDayTranslated.value}` )
            occurrenceArrTranslated.value.push($t('on the last') + ` ${selectedDayTranslated.value}` )

            vOccurrenceArr.push(`on the ${getNumber(occurrence)} ${selectedDay.value}`);
            vOccurrenceArr.push(`on the last ${selectedDay.value}`);
        } else if (occurrence == 5) {
            occurrenceArrTranslated.value.push($t('on the last') + ` ${selectedDayTranslated.value}` )

            vOccurrenceArr.push(`on the last ${selectedDay.value}`);
        } else {
            occurrenceArrTranslated.value.push($t('on the') + ` ${getNumberTranslated(occurrence)} ${selectedDayTranslated.value}` )
            vOccurrenceArr.push(`on the ${getNumber(occurrence)} ${selectedDay.value}`);
        }
    } else if (option.value === 'year' || option.value === 'years') {
        occurrenceArrTranslated.value.push($t('on') + ` ${monthsTranslated[props.startDate.getMonth()]} ${props.startDate.getDate()}` )

        vOccurrenceArr.push(`on ${props.startDate.toLocaleString('default', { month: 'long' })} ${props.startDate.getDate()}`);
        if (occurrence == 4) {
            occurrenceArrTranslated.value.push($t('on the') + ` ${getNumberTranslated(occurrence)} ${selectedDayTranslated.value} ` + $t('of') + ` ${monthsTranslated[props.startDate.getMonth()]}` )
            occurrenceArrTranslated.value.push($t('on the last') + ` ${selectedDayTranslated.value} ` + $t('of') + ` ${monthsTranslated[props.startDate.getMonth()]}` )

            vOccurrenceArr.push(`on the ${getNumber(occurrence)} ${selectedDay.value} of ${props.startDate.toLocaleString('default', { month: 'long' })}`);
            vOccurrenceArr.push(`on the last ${selectedDay.value} of ${props.startDate.toLocaleString('default', { month: 'long' })}`);
        } else if (occurrence == 5) {
            occurrenceArrTranslated.value.push($t('on the last') + ` ${selectedDayTranslated.value} ` + $t('of') + ` ${monthsTranslated[props.startDate.getMonth()]}` )
            vOccurrenceArr.push(`on the last ${selectedDay.value} of ${props.startDate.toLocaleString('default', { month: 'long' })}`);
        } else {
            occurrenceArrTranslated.value.push($t('on the') + ` ${getNumberTranslated(occurrence)} ${selectedDayTranslated.value} ` + $t('of') + ` ${monthsTranslated[props.startDate.getMonth()]}` )
            vOccurrenceArr.push(`on the ${getNumber(occurrence)} ${selectedDay.value} of ${props.startDate.toLocaleString('default', { month: 'long' })}`);
        }
    }
    return vOccurrenceArr;
})

const selectedValueMonthIndex = ref<number>(0);

watch(() => selectedValueMonthIndex.value, (newValue, oldValue) => {
    emit('update:frequency', formula.value);
})


const getNumber = (number: number) => {
    switch (number) {
        case 1:
            return 'first';
        case 2:
            return 'second';
        case 3:
            return 'third';
        case 4:
            return 'fourth';
        default:
            break;
    }
}

const getNumberTranslated = (number: number) => {
    switch (number) {
        case 1:
            return $t('first');
        case 2:
            return $t('second');
        case 3:
            return $t('third');
        case 4:
            return $t('fourth');
        default:
            break;
    }
}

const tryOptionUpdate = (pValue: number) => {
    pluralUpdate(pValue);
    if (option.value === 'days' || option.value === 'day') {
        handleDays(pValue);
    } else if (option.value === 'week' || option.value === 'weeks') {
        renderDaySelector.value = true;
        renderRadioSelector.value = false;
    } else if (option.value === 'month' || option.value === 'months') {
        selectedDay.value = [];
        selectStartDay();
        renderDaySelector.value = false;
        renderRadioSelector.value = true;
    }
    emit('update:frequency', formula.value);
}

const handleDays = (pValue: number) => {
    if (pValue == 1) {
        renderDaySelector.value = false;
        selectedDay.value = [...days];
    } else {
        renderDaySelector.value = false;
    }
}

const pluralUpdate = (pValue: number) => {
    if (pValue === 1) {
        switch (option.value) {
            case 'days':
                option.value = 'day';
                break;
            case 'weeks':
                option.value = 'week';
                break;
            case 'months':
                option.value = 'month';
                break;
            default:
                break;
        }

        return;
    };

    switch (option.value) {
        case 'day':
            option.value = 'days';
            break;
        case 'week':
            option.value = 'weeks';
            break;
        case 'month':
            option.value = 'months';
            break;
        default:
            break;
    }
}

const tryDaysUpdate = (pValue) => {
    if (pValue === 'day') {
        renderDaySelector.value = false;
        renderRadioSelector.value = false;
        selectedDay.value = [...days];
    } else if (pValue === 'days') {
        renderDaySelector.value = false;
        renderRadioSelector.value = false;
        handleDays(number.value);
    } else if (pValue === 'week' || pValue === 'weeks') {
        renderDaySelector.value = true;
        renderRadioSelector.value = false;
        selectedDay.value = [];
    } else if (pValue === 'month' || pValue === 'months') {
        renderDaySelector.value = false;
        renderRadioSelector.value = true;
        selectedDay.value = [];
        selectStartDay();
    } else if (pValue === 'year') {
        renderDaySelector.value = false;
        renderRadioSelector.value = true;
        selectedDay.value = [];
        selectStartDay();
    } else {
        renderDaySelector.value = true;
        renderRadioSelector.value = false;
    }
    emit('update:frequency', formula.value);
}

const isDaySelected = (day: string) => {
    return selectedDay.value.includes(day) ? 'btn-primary text-white' : 'btn-outline-primary text-black';
}

const handleSelect = (day?: string) => {
    if (day === undefined || option.value === 'day') {
        return;
    }

    if (option.value === 'week' || option.value === 'weeks') {
        selectedDay.value = [day];

        if (!props.startDate) {
            return;
        }
        
        window.setTimeout(() => {
            emit('update:frequency', formula.value);
        }, 20)
        return;
    }

    if (selectedDay.value.includes(day)) {
        const index = selectedDay.value.indexOf(day);
        selectedDay.value.splice(index, 1);
    } else {
        selectedDay.value.push(day);
    }
    emit('update:frequency', formula.value);
}

function getDateOfWeekday(date, dayIndex) {
    const newDate = new Date(date);

    let currentDayIndex = newDate.getDay();
    if (currentDayIndex == 0) {
        currentDayIndex = 7;
    }
    const difference = dayIndex - currentDayIndex;

    if (dayIndex == 0) {
        const diff = 7 - currentDayIndex;
        newDate.setDate(newDate.getDate() + diff);
    } else if (difference < 0) {
        newDate.setDate(newDate.getDate() + difference);
    } else if (difference === 0 && dayIndex !== currentDayIndex) {
        newDate.setDate(newDate.getDate() + difference + 7);
    } else {
        newDate.setDate(newDate.getDate() + difference);
    }

    return newDate;
}

const selectStartDay = () => {
    if (!props.startDate) {
        return;
    }

    const currentDay = new Date(props.startDate).getDay();
    selectedDay.value.push(getDayName(currentDay)!);
    if (!props.formula) {
        emit('update:frequency', formula.value);
    }
}

const getDayName = (number: number) => {
    switch (number) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        default:
            break;
    }
}

const selectWeekDay = (weekdayIndex) => {
    selectedDay.value = [getDayName(weekdayIndex)!];
}

const getDayIndex = (day: string) => {
    switch (day) {
        case 'Sunday':
            return 0;
        case 'Monday':
            return 1;
        case 'Tuesday':
            return 2;
        case 'Wednesday':
            return 3;
        case 'Thursday':
            return 4;
        case 'Friday':
            return 5;
        case 'Saturday':
            return 6;
        default:
            return 0;
    }
}

const formula = computed(() => {
    if (option.value === 'day' || option.value === 'days') {
        return `+${number.value}d`;
    } else if (option.value === 'week' || option.value === 'weeks') {
        if (selectedDay.value.length === 0) {
            return null;
        }

        const selectedDayIndex = getDayIndex(selectedDay.value[0]);
        const currentDayIndex = new Date(props.startDate).getDay();
        if (selectedDayIndex == currentDayIndex) {
            return `+${number.value}w`;
        } else {
            if (selectedDayIndex == 0) {
                return `+${number.value}w lastdayofweek`;
            } else if (selectedDayIndex == 1) {
                return `+${number.value}w firstdayofweek`;
            } else {
                const diff = selectedDayIndex - 1;
                return `+${number.value}w firstdayofweek +${diff}d`;
            }
        }
    } else if (option.value === 'month' || option.value === 'months') {
        if (selectedValueMonthIndex.value == 0) {
            return `+${number.value}m`;
        } else {
            let dayIndex = getDayIndex(selectedDay.value[0]);
            if (dayIndex == 0) {
                dayIndex = 7;
            }
            if(occurrenceArr.value.length != 0){

                if (occurrenceArr.value[selectedValueMonthIndex.value].includes('first')) {
                    return `+${number.value}m ${dayIndex}firstofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('second')) {
                    return `+${number.value}m ${dayIndex}secondofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('third')) {
                    return `+${number.value}m ${dayIndex}thirdofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('fourth')) {
                    return `+${number.value}m ${dayIndex}fourthofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('last')) {
                    return `+${number.value}m ${dayIndex}lastofmonth`;
                }
            }
        }
    } else {
        if (selectedValueMonthIndex.value == 0) {
            return `+${number.value}y`;
        } else {
            let dayIndex = getDayIndex(selectedDay.value[0]);
            if (dayIndex == 0) {
                dayIndex = 7;
            }
            if(occurrenceArr.value.length != 0){

                if (occurrenceArr.value[selectedValueMonthIndex.value].includes('first')) {
                    return `+${number.value}y ${dayIndex}firstofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('second')) {
                    return `+${number.value}y ${dayIndex}secondofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('third')) {
                    return `+${number.value}y ${dayIndex}thirdofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('fourth')) {
                    return `+${number.value}y ${dayIndex}fourthofmonth`;
                } else if (occurrenceArr.value[selectedValueMonthIndex.value].includes('last')) {
                    return `+${number.value}y ${dayIndex}lastofmonth`;
                } 
            }
        }
    }
    return null;
});

const weekDayFromFormula = (formula, startDate) => {
    if (!formula || !startDate) {
        return null;
    }

    if (props.formula.includes('lastdayofweek')) {
        return 0; // sunday
    }

    if (props.formula.includes('firstdayofweek')) {
        const daysRule = props.formula.match(/\+\d+d/);
        const daysAmount = +daysRule?.[0]?.match(/\d+/) || 0;
    
        return daysAmount + 1;
    }

    return null;
}

const applyInitialValues = () => {
    const selectedOptions = getSelectedOptions(props.formula, props.startDate) || {};

    number.value = selectedOptions.number ?? 1;
    option.value = selectedOptions.option ?? "week";
    selectedValueMonthIndex.value = selectedOptions.selectedValueMonthIndex ?? 0;
    renderDaySelector.value = selectedOptions.renderDaySelector ?? true;
    renderRadioSelector.value = selectedOptions.renderRadioSelector ?? false;

    if (option.value === "day") {
        selectedDay.value = [...days];
        return;
    } 

    if (['week', 'weeks'].includes(option.value)) {
        const repeatDayIndex = weekDayFromFormula(props.formula, props.startDate);
        if (repeatDayIndex !== null) {
            selectWeekDay(repeatDayIndex);
            return;
        }

        selectedDay.value = [];
    }
    selectStartDay();
}

const resetToDefault = () => {
    option.value = optionDefault;
    tryDaysUpdate(optionDefault);
    number.value = numberDefault;
    tryOptionUpdate(numberDefault);
}

onMounted(() => {
    applyInitialValues();
})

defineExpose({ resetToDefault });
</script>

<style scoped>
    .hover-class:hover {
        color: white !important;
    }
</style>